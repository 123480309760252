<script setup>
import { useSsrFetch } from "~/composables/useFetch";
import { useMetaStore } from '~/stores/meta.store'

const developer = ref([]);
const route = useRoute();
developer.value = await useSsrFetch(`pages/developers/show?slug=${route.params.slug}`);

if (!developer.value) {
    showError({
        statusCode: 404,
        statusMessage: "Page Not Found"
    });
}


const metaStore = useMetaStore();

metaStore.setMeta(developer.value.meta);
</script>

<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-8">
            <div class="m-auto text-center flex justify-center flex-col mt-6 border-b pb-4">
                <img :src="developer.logo" class="self-center w-40 mb-4" />
                <p>{{ developer.about || `About ${developer.name}` }}</p>
            </div>
            
            <div class="m-auto text-center flex justify-center flex-col mt-6">
                <p class="mt-4 mb-8 text-indigo-900 text-2xl font-medium">{{ `${developer.name} ${$t('client.projects')}` }}</p>
                <div class="grid grid-cols-4 gap-8">
                    <div v-for="project in developer.projects" v-bind:key="project._id">
                        <NuxtLink :to="localePath({ name: 'projects.index', params: { location: project.slug } })">
                            <div class="bg-white rounded-lg h-56 text-center m-auto w-full flex flex-col shadow-sm hover:shadow-md">
                                <img :src="project.picture" class="object-cover h-36 w-full rounded-lg rounded-b-none mb-2" />
                                <p  class="text-indigo-900 text-xl font-medium">{{ project.name }}</p>
                                <div class="text-gray-500 text-sm" v-html="project.content"></div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>